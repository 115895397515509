// Change Autocomplete styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.app_form {
  label {
    @apply text-14 font-[400] inline-block mb-[6px];
    color: #686a6d;
  }
  .input {
    @apply block h-[50px] md:h-[60px] px-4 rounded-[8px] text-[16px] text-black-300 w-[300px] flex gap-3 transition-all duration-200;
    border: 1px solid rgba(18, 18, 18, 0.3);
  }

  .signup-input {
    @apply block h-[50px] md:h-[60px] px-4 rounded-[12px] text-[16px] text-black-300 w-full transition-all duration-200;
  }
  .textarea-input {
    @apply block min-h-[70px] px-4 py-2 rounded-[8px] text-[16px] text-black-300 w-full transition-all duration-200;
    border: 1px solid rgba(18, 18, 18, 0.3);
  }

  .ticket-input {
    @apply block h-[50px] px-4 rounded-[8px] text-[16px] w-full transition-all duration-200;
  }

  .confirm-input {
    @apply block h-[50px] md:h-[60px] px-4 rounded-[12px] text-[16px] outline-none text-black-300 w-full transition-all duration-200;
  }
  select {
    @apply block h-[70px] px-4 rounded-lg text-lg text-black-300 w-full appearance-none transition-all duration-200;
    border: 1px solid rgba(18, 18, 18, 0.3);
  }
  .select-modal {
    @apply flex items-center h-[60px] px-4 rounded-lg text-[17px] text-black-300 w-full appearance-none transition-all duration-200;
    border: 1px solid rgba(18, 18, 18, 0.3);
  }
  &-error {
    @apply text-[#C63737] border-[#C63737] text-sm;
  }

  .input,
  select {
    &:focus {
      @apply border-[1.3px] border-blue outline-none;
    }
  }
  &.select {
    position: relative;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 22.5px;
      right: 20px;
      background-image: url("../../svg/icons/select-arrow.svg");
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .textarea {
    @apply w-full rounded-[8px] px-5 py-2;
  }

  &-password {
    @apply relative;

    button {
      @apply absolute top-0 right-0 pl-4 pr-6 h-full flex justify-center items-center;
    }
    input {
      @apply pr-14;
    }
  }

  &-info {
    @apply flex rounded-lg overflow-hidden;
    border: 1px solid rgba(18, 18, 18, 0.3);
    &-box {
      @apply flex items-center sm:px-7 px-4 text-grey-300 text-lg;
      background-color: rgba(18, 18, 18, 0.04);
      svg {
        @apply mr-3;
      }
      //
    }
    .input {
      @apply rounded-none border-none;
    }
  }

  &-number {
    @apply flex items-center rounded-lg overflow-hidden w-56 bg-repeat;
    border: 1px solid rgba(18, 18, 18, 0.3);
    &-box {
      @apply flex items-center px-7 text-grey-300 text-lg;
      background-color: rgba(18, 18, 18, 0.04);
      svg {
        @apply mr-3;
      }
      //
    }
    input {
      @apply rounded-none border-none focus:outline-none;
    }
    &-unit {
      @apply bg-white self-stretch flex items-center pr-5 text-black-300;
    }
    &-controls {
      @apply flex flex-col;
      border-left: 1px solid rgba(18, 18, 18, 0.3);

      & > * {
        @apply h-[70px] px-6;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(18, 18, 18, 0.2);
        }
      }
    }
  }
}
hr {
  color: rgba(31, 31, 31, 0.1);
}

.form-body {
  @apply w-full flex flex-col items-center mt-20;
  .form {
    @apply w-full bg-white min-w-[88%] lg:min-w-[auto] lg:w-[55%] flex flex-col items-center px-5 md:px-10 xl:mt-0 mx-3 sm:mx-10 py-5 xl:pt-5;
  }
  .form-header {
    @apply text-xl text-center md:text-[30px] mt-10 font-semibold;
  }
  .form-subtext {
    @apply text-[14px] text-[#13141B99] pb-10 mt-2;
  }
  .bottom-text {
    @apply text-black text-[14px] md:text-[16px] font-[400] mt-5;
    span {
      @apply text-[#005FFC] underline;
    }
  }
}

.dropdown {
  &__button {
    @apply w-full flex justify-between h-[50px] md:h-[50px] rounded-[8px] px-5 items-center;
    border: 1px solid rgba(18, 18, 18, 0.3);
    font-size: 16px;
    color: #717171;
  }

  &__options {
    @apply absolute flex flex-col justify-between items-start w-full px-5 py-3 bg-white rounded-[10px] left-0 top-[60px];
    z-index: 999;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
    .option {
      @apply w-full flex items-start py-3 pl-3 gap-3;
      font-size: 14px;
      color: #717171;

      &__apply-border {
        border-bottom: 0.5px solid #d8d8d8;
      }
    }
  }
}

.input-slider {
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  border: 2.5px solid #045cf4;
  background: #d3d3d3; /* Grey background */
  color: #045cf4;
  outline: none; /* Remove outline */
  opacity: 1;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch .radio-slider-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.radio-slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 34px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.radio-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.radio-slider-input:checked + .radio-slider {
  background-color: #045cf4;
}

.radio-slider-input:focus + .radio-slider {
  box-shadow: 0 0 1px #045cf4;
}

.radio-slider-input:checked + .radio-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.radio-slider.round {
  border-radius: 34px;
}

.radio-slider.round:before {
  border-radius: 50%;
}

.standard-input-1 {
  .label {
    @apply text-14;
    margin-bottom: 6px;
  }
  .input-box {
    @apply self-start flex gap-4 items-center px-5;
    height: 60px;
    border-radius: 12px;

    .full {
      @apply w-full;
    }

    .input {
      @apply p-0 outline-none border-none text-16 text-black;
      height: 56px;
    }
  }

  .error {
    @apply flex items-center text-14 text-red-error;
    margin-top: 6px;
    gap: 2px;

    .err-icon {
      @apply flex items-center justify-center bg-red-error rounded-full text-white;
      font-size: 12px;
      font-weight: 500;
      width: 17px;
      height: 17px;
    }
  }
}

.standard-dropdown-1 {
  .label {
    @apply text-14;
    margin-bottom: 6px;
  }
  .button {
    @apply relative w-full flex justify-between px-4 items-center text-16;
    height: 60px;
    border-radius: 12px;
    color: #8b8d90;

    .display-text {
      @apply flex items-center;
      gap: 2px;

      svg {
        width: 30px;
      }
    }

    &__options {
      @apply w-full absolute left-0 bg-white py-4 px-4 text-16 text-black flex flex-col justify-center;
      top: 68px;
      border-radius: 12px;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.06);
      z-index: 999;

      &-item {
        @apply w-full flex gap-1 items-center;
        height: 40px;

        svg {
          width: 30px;
        }
      }
    }
  }

  .error {
    @apply flex items-center text-14 text-red-error;
    gap: 2px;
    margin-top: 6px;

    .err-icon {
      @apply flex items-center justify-center bg-red-error rounded-full text-white;
      font-size: 12px;
      font-weight: 500;
      width: 17px;
      height: 17px;
    }
  }
}
