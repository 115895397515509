.ReactModal__Content {
  @apply sm:w-auto w-[90%] mx-auto;
  border-radius: 13px;
  border: none !important;
  box-shadow: 0px 8px 46px rgb(0 0 0 / 8%), 0px 2px 6px rgb(0 0 0 / 3%) !important;
}
.ReactModal__Overlay {
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.606) !important;
}

//  !important;
