.app_button {
  @apply bg-blue px-8 py-3 rounded-lg flex items-center justify-center text-white w-max transition-all duration-200;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus {
    @apply bg-blue-focus;
  }
  &.no-bg {
    @apply bg-white border-blue border-[1px] text-black-400;
  }
  &.full {
    @apply w-full;
  }
  svg {
    @apply mr-3;
  }
  &.grey {
    background-color: #97a2b6;
  }
  &.trans {
    @apply text-black-400;
    background-color: #fff;
  }
  &.white {
    @apply text-black-300;
    border: 1px solid rgba(18, 18, 18, 0.1);
    background-color: rgba(18, 18, 18, 0.02);
  }
}

.app_btn-responsive {
  @apply bg-blue-disabled group-hover:bg-blue px-8 py-3 rounded-lg flex items-center justify-center text-white w-max transition-all duration-200;
  &[disabled] {
    @apply bg-blue-disabled;
    cursor: not-allowed;
  }
  &:focus {
    @apply bg-blue-focus;
  }
  &:hover {
    @apply bg-blue-hover;
  }
  &.no-bg {
    @apply bg-white border-blue border-[1px] text-black-400;
  }
  &.full {
    @apply w-full;
  }
  svg {
    @apply mr-3;
  }
  &.grey {
    background-color: #97a2b6;
  }
  &.trans {
    @apply text-black-400;
    background-color: #fff;
  }
  &.white {
    @apply text-black-300;
    border: 1px solid rgba(18, 18, 18, 0.1);
    background-color: rgba(18, 18, 18, 0.02);
  }
}

.standard-btn-1 {
  @apply bg-blue flex items-center justify-center text-white text-18 transition-all duration-200;
  height: 60px;
  border-radius: 8px;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus {
    @apply bg-blue-focus;
  }
  &:hover {
    @apply bg-blue-hover;
  }
  &.no-bg {
    @apply bg-white border-blue border-[1px] text-black-400;
  }
  &.standard-w {
    width: 272px;
  }
  &.full {
    @apply w-full;
  }
  svg {
    @apply mr-3;
  }
  &.grey {
    background-color: #97a2b6;
  }
  &.trans {
    @apply text-black-400;
    background-color: #fff;
  }
  &.white {
    @apply text-black-300;
    border: 1px solid rgba(18, 18, 18, 0.1);
    background-color: rgba(18, 18, 18, 0.02);
  }
}

.page-slider {
  @apply flex items-center justify-between px-4;
  width: 123px;
  height: 60px;
  border-radius: 12px;
  &:hover {
    @apply border-blue;
  }

  .page-controller {
    @apply flex flex-col justify-center gap-[2px] text-black text-16;

    .change-btn {
      @apply bg-[#E7E9EC] flex items-center justify-center;
      width: 28px;
      height: 18px;
      border-radius: 6px;
    }
  }
}
