.validator {
  @apply w-full;

  &__hero {
    &-btn {
      @apply h-full w-[150px] flex gap-[2px] items-center;
    }

    &-content {
      @apply mt-6 flex items-center justify-between text-white;
    }
  }

  &__main {
    /* @apply w-full grid grid-cols-2 md:grid-cols-3 md:grid-flow-col gap-6 mt-6; */
    @apply w-full grid grid-cols-2 gap-6 mt-6;

    &-boxesContainer {
      @apply flex flex-col gap-6;
    }

    &-box {
      @apply w-auto flex flex-col justify-center px-4 md:px-8 py-5 gap-1 bg-white;
      height: 115px;
      border-radius: 12px;
      box-shadow: 0 25px 35px 0px rgba(0, 0, 0, 0.03);
    }

    &-addresses {
      /* col-span-2 md:row-span-2 */
      @apply w-auto flex flex-col p-6 bg-white;
      border-radius: 12px;
      box-shadow: 0 25px 35px 0px rgba(0, 0, 0, 0.03);

      .address {
        @apply w-full flex flex-col mt-6;

        .title {
          @apply font-[500] text-14;
          color: #3a3c3f;
        }

        .addr-box {
          @apply flex items-center justify-between bg-[#E6EFFE] h-15 px-6 mt-1;
          border: 1px solid #b1ccfc;
          border-radius: 10px;
        }

        .addr {
          @apply text-14 text-black;
        }
      }
    }
  }

  &__networks {
    @apply flex w-full bg-white flex-col mt-3 px-3 md:px-5 py-5 gap-2;
    border-radius: 8px;
    box-shadow: 0 25px 35px 0px rgba(0, 0, 0, 0.03);

    &-title {
      @apply flex justify-between items-center;
    }
  }

  &__dropdown {
    @apply absolute w-[165px] left-0 top-[68px] bg-white py-6 px-4 text-16 text-black font-[500] flex flex-col items-center;
    border-radius: 12px;

    &-item {
      @apply h-[40px] w-[150px] flex gap-[2px] items-center justify-center;
    }
  }
}
