.app-home {
  scroll-behavior: smooth;
  width: 100%;
  transition: all 1 ease-in-out;
  &__heading {
    display: flex;
    @apply flex justify-start items-center gap-3;
    h2 {
      @apply text-black;
      font-weight: 500;
      font-size: 24px;
    }
  }

  &__hero {
    @apply w-full mt-6 p-8 bg-blue;
    background-image: url("../../img/hero-bg-strokes.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-position-x: -150px;
    border-radius: 8px;

    .shadow {
      box-shadow: 0 25px 35px 0px rgba(0, 0, 0, 0.03);
    }

    &-header {
      display: flex;
      @apply flex items-center text-white text-18 font-[600] gap-3 h-13;
      svg {
        height: auto;
        @apply w-11;
      }
    }
    .text {
      font-weight: 400;
      @apply text-white text-16  mt-2 mr-5;
    }
    .start-button {
      font-weight: 500;
      height: 60px;
      width: 272px;
      border-radius: 8px;
      margin-top: 24px;
      border: 1px solid white;
      a {
        @apply p-3 px-8 text-18 text-white;
      }
    }
    .network-button {
      @apply text-16 flex items-center justify-between px-4 text-white;
      font-weight: 500;
      height: 58px;
      width: 165px;
      border-radius: 12px;
      border: 1px solid white;
    }
  }

  &__networks {
    @apply w-full mt-6;
  }
}
