@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@layer base {
  /* @font-face {
    font-family: "BR Firma";
    src: url("../../fonts/BRFirma-Regular.woff2") format("woff2");
    font-weight: normal;
    font-display: fallback;
    font-style: normal;
  }

  @font-face {
    font-family: "BR Firma";
    src: url("../../fonts/BRFirma-Medium.woff2") format("woff2");
    font-weight: 500;
    font-display: fallback;
    font-style: normal;
  }

  @font-face {
    font-family: "BR Firma";
    src: url("../../fonts/BRFirma-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-display: fallback;
    font-style: normal;
  }

  @font-face {
    font-family: "BR Firma";
    src: url("../../fonts/BRFirma-Bold.woff2") format("woff2");
    font-weight: 700;
    font-display: fallback;
    font-style: normal;
  } */

  html,
  *,
  body {
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.15px;
  }
}
