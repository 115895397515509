.api-services {
  @apply text-black;
  scroll-behavior: smooth;
  width: 100%;
  transition: all 1 ease-in-out;
  display: flex;
  flex-direction: column;

  &__hero {
    @apply w-full flex justify-between items-center mt-6 p-8;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);

    .left-hero {
      @apply w-full;
      @media screen and (min-width: 767px) {
        width: 60%;
      }
    }
    .right-hero {
      @apply hidden items-center justify-end;
      @media screen and (min-width: 767px) {
        width: 40%;
        display: flex;
      }
    }
    &-header1 {
      display: flex;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      @apply flex items-center text-black;
    }

    &-header2 {
      display: flex;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      max-width: 250px;
      @apply flex items-center text-black;
    }
    &-text {
      @apply text-16;
      color: #515356;
      max-width: 524px;
    }
  }

  &__networks {
    width: 100%;
    @apply w-full flex flex-col items-center gap-3 mt-6;
    h2 {
      font-size: 22px;
      font-weight: 500;
      @apply md:self-start;
    }
  }

  &__modal {
    @apply sm:w-[80vw] md:w-[65vw] xl:w-[538px] pt-8 px-8;
    margin-bottom: 56px;
    &-header {
      @apply w-full flex justify-end items-center;
    }
  }
}

.projects {
  @apply w-full flex flex-col;

  &__display {
    @apply w-full relative min-h-[60vh] flex flex-col justify-between gap-5 bg-white mt-6 py-8;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);
    &-header {
      @apply w-full flex flex-col gap-4 md:flex-row md:justify-between items-center px-8;
      font-size: 24px;
      font-weight: 500;
    }
    &-footer {
      @apply w-full flex items-center justify-end px-8;
    }
  }
}

.project-insight {
  @apply flex flex-col w-full gap-6 text-black;

  &__hero {
    @apply w-full h-auto flex flex-col md:flex-row items-center gap-6 p-8 bg-white;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);

    @media screen and (min-width: 1720px) {
      @apply pr-40;
    }
    border-radius: 10px;

    &-card {
      @apply flex flex-col items-center border-[2px] border-blue pt-10;
      width: 350px;
      min-width: 350px;
      height: 365px;
      border-radius: 10px;

      svg {
        width: 118px;
        height: 118px;
      }

      h2 {
        @apply mt-3;
        font-size: 22px;
        font-weight: 500;
      }

      p {
        @apply mt-20 text-18;
        font-weight: 500;

        span {
          color: #aeafb2;
        }
      }
    }
  }
  &__api {
    @apply flex flex-col bg-white w-full p-8 gap-6 relative text-black;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    &-title {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &__graph {
    @apply w-full bg-white px-8 pt-8 text-black;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    &-text {
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 500;
      span {
        font-size: 14px;
        color: #767676;
      }
    }
  }
}
