.dedicated {
  @apply w-full flex flex-col;

  &__page {
    @apply flex flex-col gap-7;

    &-empty {
      @apply w-full h-[70vh] bg-white flex flex-col items-center justify-center gap-5;

      box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
      border-radius: 8px;
    }

    h2 {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .button {
    font-size: 15px;
    font-weight: 500;
    height: 45px;
    font-size: 16px;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 20px;
    a {
      padding: 16px;
      @apply p-3 px-4;
    }

    &__blue {
      color: #ffffff;
      background-color: #045cf4;
    }

    &__white {
      background-color: #ffffff;
      color: #045cf4;
      border: 1px solid #045cf4;
    }
  }
}

.create {
  @apply w-full flex flex-col gap-5;

  &__back {
    @apply flex gap-2 items-center;

    font-size: 13px;
    font-weight: 600;
  }
}

.step {
  @apply w-full bg-white flex flex-col items-center p-5 md:py-8 xl:px-7;

  box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
  border-radius: 8px;

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .slider {
    @apply w-full mt-5;

    font-size: 14px;

    &__header {
      @apply flex items-center;

      &-button {
        @apply w-[50%] text-center py-2;

        font-size: 18px;
      }

      &-button4 {
        @apply text-center py-2;

        font-size: 14px;
        @media screen and (min-width: 756px) {
          font-size: 16px;
        }
        @media screen and (min-width: 1280px) {
          font-size: 16px;
        }
      }
    }

    &__border {
      @apply w-full h-[5px] bg-[#EAEAEA] rounded-[5px] flex;
    }

    &__border2 {
      @apply h-[5px] bg-[#EAEAEA] rounded-[5px] flex;
    }
  }
}

.dedicatedProject {
  &__header {
    @apply flex items-start justify-between;

    &-btn {
      @apply flex w-[60px] flex-col items-center justify-between gap-1;

      font-size: 10px;
      color: #1f1f1f99;
    }
  }

  &__insight {
    @apply w-auto bg-white px-5 flex flex-col justify-center;

    box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
    border-radius: 16px;

    &-title {
      font-size: 12px;
      color: #1f1f1f99;
    }

    &-value {
      @apply truncate;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__dataBox {
    @apply flex flex-col gap-3 md:grid grid-cols-3 mt-10;
  }

  &__rpcApi {
    @apply w-full grid md:grid-cols-2 mt-7 gap-3 md:gap-5 2xl:gap-10;

    &-value {
      font-size: 14px;
    }
  }
}

.pricing-summary {
  @apply w-full flex flex-col justify-center pl-14;
  background-image: url("../../img/pricing-bg.png");
  background-size: cover;
  height: 150px;
  border-radius: 12px;
  color: white;

  p {
    font-size: 14px;
  }

  h1 {
    font-size: 45px;
  }
}

.node-button {
  font-size: 15px;
  font-weight: 500;
  height: 45px;
  font-size: 16px;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  a {
    padding: 16px;
    @apply p-3 px-4;
  }

  &__blue {
    color: #ffffff;
    background-color: #045cf4;
  }

  &__white {
    background-color: #ffffff;
    color: #045cf4;
    border: 1px solid #045cf4;
  }
}
