.side-nav {
  @apply w-[270px] h-screen min-h-[700px] flex-col justify-between relative left-[-270px] xl:left-0 top-0 z-[99] bg-[#0C0F20] text-14;
  width: 270px;

  &__header {
    @apply w-full h-[70px] flex items-center justify-between pl-7 pt-7;
    .navcontroller {
      @apply mr-7;
    }
  }

  .menu-text {
    @apply flex items-end mt-[55px] text-white opacity-50 text-[10px] pl-10 uppercase;
  }

  &__cont {
    @apply flex-grow flex flex-col justify-between;

    &-bottom {
      &-links {
        @apply mb-[57px] mx-7;
        .nav_link {
          display: flex;
          svg {
            @apply fill-white;
          }
          span {
            @apply text-white;
          }
          &.doc {
            @apply justify-between;
          }
        }
      }
      &-socials {
        @apply py-9 px-12 flex items-center justify-between;
      }
    }
    &-top {
      @apply mt-2 mx-7;
      .nav_link {
        display: flex;
        svg {
          @apply mr-0 fill-grey;
        }
        span {
          @apply text-white;
        }
      }
    }
  }

  .nav_link {
    @apply flex items-center gap-2 h-[42px] px-3;
    transition: all 0.4s ease;
    border: 1px solid #0c0f20;
    border-radius: 8px;
    &:hover {
      border-color: #01173d;
      background-color: #01173d;
    }
    &.active {
      @apply bg-blue;
      svg {
        stroke-width: 0px;
        path {
          fill: white;
        }
        fill: white;
      }
      span {
        @apply text-white;
      }
    }

    svg {
      stroke-width: 1px;
    }

    span {
      font-weight: 400;
    }
  }
}

.min-nav {
  @apply h-screen flex flex-col justify-between fixed left-0 top-0 z-[99] bg-[#0C0F20];
  width: 92px;

  &__header {
    @apply w-full flex items-center justify-center pt-7;
    height: 70px;
    .navcontroller {
      height: 30px;
      padding-left: 6px;
      padding-right: 6px;
      border: 1.5px solid #333e77;
      border-radius: 2.5px;
    }
  }

  &__cont {
    @apply flex-grow flex flex-col justify-between;
    margin-top: 76px;

    &-bottom {
      &-links {
        margin-bottom: 57px;
        .nav_link {
          svg {
            @apply fill-white;
          }
          span {
            @apply text-white;
          }
          &.doc {
            @apply justify-between;
          }
        }
      }
      &-socials {
        @apply py-9 px-12 flex items-center justify-between;
      }
    }
    &-top {
      @apply mt-2;
      .min_link {
        span {
          @apply text-white text-16;
        }
      }
    }
    .nav-name {
      @apply hidden absolute left-[118px] text-white text-14 font-[400] flex items-center;
      svg {
        margin-right: -1px;
      }

      span {
        @apply flex items-center text-center h-9 px-[10px] bg-[#000A1B];
        white-space: nowrap;
        border-radius: 10px;
      }
    }
  }

  .min_link {
    @apply flex items-center justify-center h-[42px] w-[42px];
    transition: all 0.4s ease;
    border: 1px solid #0c0f20;
    border-radius: 8px;
    &:hover {
      border-color: #01173d;
      background-color: #01173d;
    }
    &.active {
      @apply bg-blue;
      svg {
        stroke-width: 0px;
        path {
          fill: white;
        }
        fill: white;
      }
      span {
        @apply text-white;
      }
    }
    span {
      font-weight: 400;
    }
    svg {
      stroke-width: 1.2px;
    }
  }
}
