.block-app {
  @apply flex flex-row;
  display: flex;
  &__view {
    @apply w-full xl:max-w-[calc(100%-92px)] absolute right-0 bg-[#F7F9FC] flex-grow h-screen max-h-screen pt-[112px] overflow-y-auto;

    .main {
      @apply px-6 md:px-10 xl:px-[72px] pb-20;
      @media screen and (min-width: 1720px) {
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }

  &-auth {
    @apply bg-[#090c21] h-screen flex flex-col pb-12;

    &__header {
      @apply py-14 md:px-32 px-6;
    }
    &-cont {
      @apply flex justify-center h-full pb-12 overflow-y-auto;
    }
    &-body {
      @apply sm:max-w-xl max-w-[90%] sm:min-w-[556px] min-w-[90%] h-full;
    }
  }
}

.cut-text {
  @apply overflow-ellipsis truncate;
}

.copied-popup {
  @apply absolute right-0 top-5 bg-[#6E6E6F] text-white text-[12px] p-1 rounded-lg;
}
