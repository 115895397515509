.api-networks {
  transition: all 0.4 ease;
  @apply w-full flex flex-wrap justify-center lg:grid lg:grid-cols-3 gap-6;
  @media screen and (min-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &__card {
    border: 2px solid #e7e9ec;
    transition: all 0.4 ease;
    &:hover {
      border-color: #045cf4;
    }
    @apply relative flex flex-col bg-white h-auto w-[300px] lg:w-auto p-8;
    border-radius: 10px;
    .network-icon {
      display: flex;
      width: 92px;
      svg {
        height: auto;
        width: 92px;
      }
    }
    h2 {
      @apply font-[600] mt-6 text-18 text-black;
    }
    .description-text {
      @apply h-[120px] text-grey-dark;
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &-header {
      @apply flex justify-start items-center gap-3 text-black;
      font-size: 22px;
      font-weight: 500;

      svg {
        width: 92px;
        height: 92px;
      }
    }
  }
}

.small-network-svg {
  svg {
    width: 30px;
  }
}
