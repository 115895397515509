@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./components/nav";
@import "./components/header";
@import "./components/button";
@import "./components/table";
@import "./components/form";
@import "./components/slider";
@import "./components/steps";
@import "./components/react-modal";
@import "./layout/auth";
@import "./layout/dashboard";
@import "./pages/projects";
@import "./pages/home";
@import "./pages/settings";
@import "./pages/auth";
@import "core/fonts";
@import "./resources/projects";
@import "./components/api-services";
@import "./pages/api-services";
@import "./pages/validator-nodes";
@import "./pages/dedicated-nodes";
@import "./pages/support";
