.app-header {
  @apply flex w-[calc(100%-270px)] h-[80px] items-center justify-between py-8 md:px-11 xl:px-[72px] px-6 bg-white fixed z-10 top-0 right-0 text-16;
  @media screen and (min-width: 1720px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  border-bottom: 1px solid rgba(18, 18, 18, 0.1);
  &__options {
    @apply flex items-center;
    button {
      @apply xl:hidden block w-5 h-5 mr-4;
    }
  }
  &__path {
    &-main {
      @apply text-black;
    }
    &-sub {
      @apply text-black;
    }
  }
  &__dropdown {
    @apply relative text-16 text-black;
    button {
      @apply flex items-center;
      .arrow {
        @apply ml-3 fill-grey-300;
      }
    }
    &_cont {
      @apply bg-white text-16 py-4 absolute top-[64px] right-0;
      border-radius: 10px;
      box-shadow: 0 25px 35px 0px rgba(0, 0, 0, 0.03);
      width: 164px;

      ul {
        li {
          @apply bg-white pl-5 flex items-center;
          height: 40px;
          button,
          a {
            @apply w-full flex items-center;
            svg {
              @apply mr-2;
            }
          }
          &:hover {
            background-color: #f4f6f9;
          }
        }
      }
    }
  }
}
